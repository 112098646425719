// ======================================== AUTH MESSAGE ========================================
export const GET_PROFILE_DATA = ["Error occure while fetch profile"];

export const LOGIN_MESSAGE = [
  "Login successfully.",
  "Please enter a valid email address or password!",
  "Something is wrong while login, please try again.",
  "Your account has been restricted by the administrator, Please contact the administrator",
];

export const REGISTER_MESSAGE = [
  "Register successfully.",
  "Something is wrong while register, please try again.",
];

export const RESET_MESSAGE_MESSAGE = [
  "Successfully send link to your email address.",
  // "Please enter a valid email address.",
  "Entered email address is not registred to the system.",
  // "User not found",
  "Something is wrong while Reset Password, please try again.",
];

export const FORGOT_PASSWORD_MESSSAGE = [
  "Password reset successfully.",
  "Something is wrong while change password, please try again.",
  "User Does Not Exist",
];

// ========================================  DASHBOARD MESSAGE  ========================================

export const GET_DASHBOARD_DATA_MESSAGE = ["Error occure while fetching data."];

export const ADD_TILES_MESSAGE = [
  "Add graph successfully.",
  "Error occure while add graph, please try again.",
];

export const DELETE_TILE_MESSAGE = [
  "Graph deleted successfully.",
  "Error occure while delete graph, please try again.",
];
export const EDIT_TILES_MESSAGE = [
  "Graph edited successfully.",
  "Error occure while edit graph, please try again.",
];

export const SEND_MAIL_MESSAGE = [
  "Mail send successfully.",
  "Error occure while sending mail, please try again.",
];

// ========================================  DATA SOURCE MESSAGE  ========================================

export const UPLOAD_DATA_FILE_MESSAGE = [
  "File upload successfully.",
  "Error occure while upload data, please try again.",
  "You cannot upload multiple files during the free trial.",
];

export const DELETE_DATA_FILE_MESSAGE = [
  "File deleted successfully.",
  "Error occure while deleting data, please try again.",
];

export const ACCESS_DATA_FILE_MESSAGE = [
  "File access update successfully.",
  "Error occure while access data file, please try again.",
];

export const EDIT_DATA_FILE_NAME_MESSAGE = [
  "File name edited successfully.",
  "Error occure while edit file name, please try again.",
];
export const LIST_DATA_FILE_MESSAGE = [
  "Error occure while get uploaded files, please try again.",
];

// ========================================  INQUIRY MODULE MESSAGE  ========================================

export const INQUIRY_CHAT_MESSAGE = [
  "Error occure while get response, please try again.",
];

export const INQUIRY_GET_LIST_MESSAGE = [
  "Error occure while get inquiry list, please try again.",
];

export const SEND_INQUIRY_MAIL_MESSAGE = [
  "Inquiry send successfully.",
  "Error occure while send inquiry, please try again.",
];

export const DELETE_INQUIRY_CHAT_MESSAGE = [
  "Inquiry deleted successfully.",
  "Error occure while delete inquiry, please try again.",
];

// ========================================  REPORTS MODULE MESSAGE  ========================================

export const GET_TEMPLATE_DATA_MESSAGE = [
  "Error occure while get template data, please try again.",
];
// +++++++++++++++ WRITTEN REPORTS +++++++++++++++
export const WRITTEN_REPORT_CREATE_MESSAGE = [
  "Written report send successfully.",
  "Error occure while create written report, please try again.",
  "You have reached your limit for creating additional written reports. To create more reports, please upgrade your plan.",
];
export const WRITTEN_REPORT_DELETE_MESSAGE = [
  "Written report deleted successfully.",
  "Error occure while delete written report, please try again.",
];

export const WRITTEN_REPORT_LIST_MESSAGE = [
  "Error occure while get written report list, please try again.",
];

export const WRITTEN_REPORT_RESEND_MESSAGE = [
  "Written report send successfully.",
  "Error occure while send report, please try again.",
];

// ++++++++++++++++ SCHEDULE REPORTS +++++++++++++++
export const SCHEDULE_REPORT_LIST_MESSAGE = [
  "Error occure while get schedule report list, please try again.",
];

export const SCHEDULE_REPORT_CREATE_MESSAGE = [
  "Schedule report created successfully.",
  "Error occure while create schedule report, please try again.",
  "You have reached your limit for creating additional scheduled reports. To create more reports, please upgrade your plan.",
  "A report with the same template, schedule type, and report repitition already exists in the database.",
];

export const SCHEDULE_REPORT_DELETE_MESSAGE = [
  "Schedule report deleted successfully.",
  "Error occure while delete schedule report, please try again.",
];

export const SCHEDULE_REPORT_CHECK_DATA_MESSAGE = [
  "Error occure while get report, please try again.",
];
export const SCHEDULE_REPORT_UPDATE_STATUS_MESSAGE = [
  "Schedule report updated successfully.",
  "Error occure while update schedule report, please try again.",
];

// ========================================  SUB USERS MODULE MESSAGE  ========================================

export const SUB_USER_CREATE_MESSAGE = [
  "User created successfully.",
  "Error occure while create user, please try again.",
];
export const SUB_USER_EDIT_MESSAGE = [
  "User edit successfully.",
  "Error occure while edit user, please try again.",
];
export const SUB_USER_DELETE_MESSAGE = [
  "User deleted successfully.",
  "Error occure while delete user, please try again.",
];
export const SET_SUB_USER_PASSWORD_MESSAGE = [
  "Password set successfully.",
  "Error occure while set password, please try again.",
];

// ========================================  TEMPLATES  MODULE MESSAGE  ========================================

export const TEMPLATE_CREATE_MESSAGE = [
  "Template created successfully.",
  "Error occure while create template, please try again.",
];

export const TEMPLATE_GET_LIST_MESSAGE = [
  "Error occure while get template list, please try again.",
];
export const TEMPLATE_DELETE_MESSAGE = [
  "Template deleted successfully.",
  "Error occure while delete template, please try again.",
];
export const TEMPLATE_UPDATE_MESSAGE = [
  "Template update successfully.",
  "Error occure while update template, please try again.",
];

// ========================================  PAYMENT MODULE ERROR  ========================================

export const CREATE_CHECKOUT_MESAGE = [
  "Error occure while create checkout session, please try sfter some time.",
];
export const CANCEL_SUBSCRIPTION_MESSAGE = [
  "Subscription cancel successfully.",
  "Error occure while cancel subscription, please try sfter some time.",
];
